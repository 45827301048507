import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Flex,
    HStack,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
} from '@chakra-ui/react';

import {
    fetchUploads,
    selectOrganization,
    selectOrganizationIsLoading,
    selectOrganizationMembers,
    addUser,
} from '../../reducers/organization';
import { useAppDispatch, useAppSelector } from '../../util/hooks';
import {
    lightGray,
    spacing1,
    spacing10,
    spacing2,
    spacing4,
    spacing6,
    spacing8,
} from '../../util/styles';
import PageHeading from '../PageHeading';
import AddUserModal from '../AddUserModal';
import { selectUser } from '../../reducers/auth';

import {
    membershipsListType,
    NewUserParamsType,
} from '../../types/reducers/organizations';
import UserDrawer from '../UserDrawer';

const Users = () => {
    const toast = useToast();
    const { t } = useTranslation('organizationPage');
    const { t: tu } = useTranslation('usersPage');
    const { t: ta } = useTranslation('addUserModal');
    const dispatch = useAppDispatch();
    const organization = useAppSelector(selectOrganization);
    const members = useAppSelector(selectOrganizationMembers);
    const user = useAppSelector(selectUser);
    const loading = useAppSelector(selectOrganizationIsLoading);
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] =
        useState<membershipsListType | null>(null);

    useEffect(() => {
        if (organization) {
            dispatch(fetchUploads(organization.id));
        }
    }, [organization, dispatch]);

    if (!organization || !user || loading) {
        return (
            <Flex width='100%' justifyContent='center'>
                <Spinner />
            </Flex>
        );
    }

    const addNewUser = (formData: NewUserParamsType) => {
        dispatch(
            addUser(
                organization.id,
                formData,
                () => {
                    setAddUserModalOpen(false);
                    toast({
                        position: 'top',
                        title: t('success'),
                        description: ta('successMessage'),
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                },
                () => {
                    toast({
                        position: 'top',
                        title: t('failure'),
                        description: ta('failureMessage'),
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            )
        );
    };

    const renderRow = (user: membershipsListType) => {
        const {
            user_email,
            user_display_name,
            role,
            invite_status,
            membership_is_active,
        } = user;

        return (
            <Tr
                key={user_email}
                sx={{
                    '&:hover': {
                        backgroundColor: lightGray,
                    },
                }}
                onClick={() => setSelectedUser(user)}
            >
                <Td>{user_display_name}</Td>
                <Td>{user_email}</Td>
                <Td>{t(role)}</Td>
                <Td>{tu(invite_status)}</Td>
                <Td>{membership_is_active ? tu('active') : tu('inactive')}</Td>
            </Tr>
        );
    };

    return (
        <VStack
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='flex-start'
            gap={spacing10}
        >
            {selectedUser && (
                <UserDrawer
                    organizationId={organization.id}
                    user={selectedUser}
                    isOpen={!!selectedUser}
                    onClose={() => setSelectedUser(null)}
                />
            )}
            <AddUserModal
                isOpen={addUserModalOpen}
                onClose={() => setAddUserModalOpen(false)}
                onSubmit={addNewUser}
            />
            <PageHeading title={t('users')} />
            <VStack
                padding={spacing6}
                width='100%'
                backgroundColor='white'
                gap={spacing8}
            >
                <HStack
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                >
                    <VStack gap={spacing1} alignItems='flex-start'>
                        <Text variant='headingMd'>{t('people')}</Text>
                        <Text variant='small'>{t('usersDescription')}</Text>
                    </VStack>
                    <HStack gap={spacing4} alignItems='center'>
                        <Button
                            variant='primary'
                            onClick={() => setAddUserModalOpen(true)}
                        >
                            {ta('addUser')}
                        </Button>
                    </HStack>
                </HStack>

                {members && members.length > 0 ? (
                    <TableContainer
                        width='100%'
                        justifyContent='space-evenly'
                        overflowY='auto'
                    >
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>{t('name')}</Th>
                                    <Th>{t('email')}</Th>
                                    <Th>{t('role')}</Th>
                                    <Th>{t('invite')}</Th>
                                    <Th>{t('status')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {members.map((m: membershipsListType) =>
                                    renderRow(m)
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Flex
                        width='100%'
                        minH='90px'
                        border={`1px solid ${lightGray}`}
                        padding={spacing2}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <Text variant='small'>{t('none')}</Text>
                    </Flex>
                )}
            </VStack>
        </VStack>
    );
};

export default Users;
