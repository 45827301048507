import { Flex, VStack } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router';

import { useAppDispatch, useAppSelector } from '../util/hooks';
import {
    fetchOrganization,
    selectIsAdmin,
    selectOrganization,
} from '../reducers/organization';
import DelayedSpinner from './DelayedSpinner';

import { selectIsStaff } from '../reducers/auth';
import {
    footerHeight,
    headerHeight,
    orgPageHeaderHeight,
    pagePadding,
} from '../util/styles';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import OrganizationPageHeader from './OrganizationPageHeader';
import { orgPageType, tabType } from '../types/types';
import Profile from './tabs/Profile';
import Network from './tabs/Network';
import Dashboards from './tabs/Dashboards';
import YourData from './tabs/YourData';
import Users from './tabs/Users';
import {
    HOME,
    DASHBOARDS,
    NETWORK,
    YOUR_DATA,
    SHARED_DATA,
    PROFILE,
    USERS,
} from '../util/constants';
import SharedData from './tabs/SharedData';
import Home from './tabs/Home';
import ReviewForm from './ReviewForm';

const OrganizationPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const organization = useAppSelector(selectOrganization);
    const is_staff = useAppSelector(selectIsStaff);
    const is_admin = useAppSelector(selectIsAdmin);
    const { id: organization_id } = useParams();

    const initialPage = (location.hash.replace('#', '') ||
        'HOME') as orgPageType;

    let initialTab: tabType = 'HOME';
    switch (initialPage) {
        case 'YOUR_DATA':
        case 'SHARED_DATA':
            initialTab = 'DATA';
            break;
        case 'PROFILE':
        case 'USERS':
            initialTab = 'SETTINGS';
            break;
        default:
            initialTab = initialPage as tabType;
            break;
    }

    const [tab, setTab] = useState<tabType>(initialTab);
    const [pageState, setPageState] = useState<orgPageType>(initialPage);

    const setPage = (page: orgPageType) => {
        setPageState(page);
        const newHash = page === 'HOME' ? '' : `#${page}`;
        navigate(location.pathname + newHash, { replace: true });
    };

    useEffect(() => {
        is_staff &&
            organization_id &&
            dispatch(fetchOrganization(organization_id));
    }, [organization_id, dispatch, is_staff]);

    if (!organization) {
        return (
            <Flex width='100%' justifyContent='center'>
                <DelayedSpinner />
            </Flex>
        );
    }

    if (!organization.reviewed && is_admin) {
        return <ReviewForm organization={organization} />;
    }

    return (
        <VStack width='100%' height='100%' gap='0px'>
            <OrganizationPageHeader
                tab={tab}
                setTab={setTab}
                page={pageState}
                setPage={setPage}
            />
            <Flex
                width='100%'
                height={
                    pageState === HOME
                        ? `calc(100vh - ${
                              headerHeight +
                              orgPageHeaderHeight +
                              footerHeight +
                              20
                          }px)`
                        : '100%'
                }
                padding={pageState !== HOME ? pagePadding : '0px'}
            >
                {pageState === HOME && <Home />}
                {pageState === DASHBOARDS && <Dashboards />}
                {pageState === NETWORK && <Network />}
                {pageState === YOUR_DATA && <YourData />}
                {pageState === SHARED_DATA && <SharedData />}
                {pageState === PROFILE && <Profile />}
                {pageState === USERS && <Users />}
            </Flex>
        </VStack>
    );
};

export default OrganizationPage;
