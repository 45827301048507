import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseFill } from 'react-icons/ri';

import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Input,
    Select,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';

import theme from '../theme';
import * as api from '../api';
import { languageType, UserMembershipUpdateType } from '../types/reducers/auth';
import { useAppDispatch, useAppSelector } from '../util/hooks';
import { inputStyles, selectStyle } from '../util/styles';
import { selectLanguage } from '../reducers/app';
import { updateUserMembership } from '../reducers/organization';
import {
    membershipsListType,
    membershipRoleTypes,
} from '../types/reducers/organizations';
import StaffOnlyCard from './StaffOnlyCard';

const { lightGray } = theme.colors.brand;

const inputRowStyles = {
    alignItems: 'flex-start',
    gap: '4px',
    width: '100%',
};

const UserDrawer = ({
    organizationId,
    user,
    isOpen,
    onClose,
}: {
    organizationId: string;
    user: membershipsListType;
    isOpen: boolean;
    onClose: () => void;
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('userDrawer');
    const { t: tc } = useTranslation('common');
    const { t: to } = useTranslation('organizationPage');
    const { t: tu } = useTranslation('usersPage');
    const toast = useToast();
    const userSelectedLanguage = useAppSelector(selectLanguage);

    const successToast = (message: string) => {
        toast({
            position: 'top',
            title: t('success'),
            description: message,
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    const failureToast = (message: string) => {
        toast({
            position: 'top',
            title: t('failure'),
            description: message,
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    };

    const {
        user_email,
        user_display_name,
        user_preferred_language,
        role: initial_role,
        invite_status,
        invited_by_name,
        joined_at,
    } = user;

    const [actionRunning, setActionRunning] = useState<boolean>(false);

    const [email, setEmail] = useState<string>();
    const [language, setLanguage] = useState<languageType>();
    const [name, setName] = useState<string>();
    const [role, setRole] = useState<membershipRoleTypes>();

    useEffect(() => {
        setEmail(user_email);
        setLanguage(user_preferred_language);
        setName(user_display_name);
        setRole(initial_role);
    }, [user_email, user_display_name, user_preferred_language, initial_role]);

    return (
        <Drawer
            placement='right'
            size='md'
            variant='profile'
            onClose={onClose}
            isOpen={isOpen}
        >
            <DrawerOverlay />
            <DrawerContent padding='10px'>
                <DrawerHeader>
                    <HStack
                        width='100%'
                        height='40px'
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Text variant='headingMd'>
                            {user_display_name || user_email}
                        </Text>
                        <Flex
                            width='34px'
                            height='34px'
                            alignItems='center'
                            justifyContent='center'
                            onClick={onClose}
                            borderRadius='4px'
                            _hover={{
                                background: lightGray,
                            }}
                        >
                            <RiCloseFill height='24px' />
                        </Flex>
                    </HStack>
                </DrawerHeader>
                <DrawerBody>
                    <VStack gap='24px' width='100%' marginBottom='24px'>
                        <HStack
                            width='100%'
                            alignItems='left'
                            justifyContent='space-between'
                        >
                            <VStack alignItems='left'>
                                <span>{t('invitedBy')}</span>
                                <span>{invited_by_name}</span>
                            </VStack>
                            <VStack alignItems='left'>
                                <span>{t('joinDate')}</span>
                                <span>
                                    {new Date(joined_at).toLocaleDateString(
                                        userSelectedLanguage,
                                        {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                        }
                                    )}
                                </span>
                            </VStack>
                            <VStack alignItems='left'>
                                <span>{t('invite')}</span>
                                <span>{tu(invite_status)}</span>
                            </VStack>
                        </HStack>
                    </VStack>
                    <VStack gap='24px' width='100%'>
                        <VStack {...inputRowStyles}>
                            <Text
                                variant={'small'}
                                textTransform={'capitalize'}
                            >
                                {tc('name')}
                            </Text>
                            <Input
                                {...inputStyles}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </VStack>
                        <VStack {...inputRowStyles}>
                            <Text
                                variant={'small'}
                                textTransform={'capitalize'}
                            >
                                {tc('email')}
                            </Text>
                            <Input
                                disabled={true}
                                {...inputStyles}
                                value={email}
                            />
                        </VStack>
                        <VStack {...inputRowStyles}>
                            <Text
                                variant={'small'}
                                textTransform={'capitalize'}
                            >
                                {tc('preferredLanguage')}
                            </Text>
                            <Select
                                style={selectStyle}
                                value={language ? language : undefined}
                                placeholder={
                                    !language ? t('selectLanguage') : undefined
                                }
                                onChange={e =>
                                    setLanguage(
                                        e.target.value as typeof language
                                    )
                                }
                            >
                                <option value='en'>{tc('en')}</option>
                                <option value='es'>{tc('es')}</option>
                            </Select>
                        </VStack>
                        <VStack {...inputRowStyles}>
                            <Text
                                variant={'small'}
                                textTransform={'capitalize'}
                            >
                                {tc('role')}
                            </Text>
                            <Select
                                style={selectStyle}
                                value={role ? role : undefined}
                                onChange={e =>
                                    setRole(
                                        e.target.value as membershipRoleTypes
                                    )
                                }
                            >
                                <option value='USER'>{to('USER')}</option>
                                <option value='READ_ONLY'>
                                    {to('READ_ONLY')}
                                </option>
                                <option value='ADMIN'>{to('ADMIN')}</option>
                            </Select>
                        </VStack>
                    </VStack>
                </DrawerBody>
                <DrawerFooter pt='16px'>
                    <StaffOnlyCard
                        title={t('userSettings')}
                        subheading={t('onlyCosaUsersCanSeeThisSection')}
                    >
                        <VStack width='100%'>
                            <HStack width='100%' alignItems='center'>
                                <Button
                                    isDisabled={actionRunning}
                                    width='50%'
                                    height='32px'
                                    variant='primary'
                                    onClick={() => {
                                        setName(user_display_name);
                                        setLanguage(user_preferred_language);
                                        setRole(initial_role);
                                    }}
                                >
                                    {t('discard')}
                                </Button>
                                <Button
                                    isDisabled={actionRunning}
                                    height='32px'
                                    width='50%'
                                    variant='submit'
                                    onClick={async () => {
                                        setActionRunning(true);
                                        const payload: UserMembershipUpdateType =
                                            {
                                                preferred_language: language,
                                                display_name: name,
                                                role: role,
                                                is_active: undefined,
                                            };
                                        dispatch(
                                            updateUserMembership(
                                                organizationId,
                                                user.membership_id,
                                                payload,
                                                () => {
                                                    setActionRunning(false);
                                                    onClose();
                                                    successToast(
                                                        t('successMessage')
                                                    );
                                                },
                                                () => {
                                                    setActionRunning(false);
                                                    failureToast(
                                                        t('failureMessage')
                                                    );
                                                }
                                            )
                                        );
                                    }}
                                >
                                    {t('save')}
                                </Button>
                            </HStack>
                            <HStack width='100%' alignItems='center'>
                                <Button
                                    isDisabled={actionRunning}
                                    height='32px'
                                    width='50%'
                                    onClick={() => {
                                        setActionRunning(true);
                                        dispatch(
                                            updateUserMembership(
                                                organizationId,
                                                user.membership_id,
                                                {
                                                    is_active:
                                                        !user.membership_is_active,
                                                } as UserMembershipUpdateType,
                                                () => {
                                                    setActionRunning(false);
                                                    onClose();
                                                    successToast(
                                                        t('successMessage')
                                                    );
                                                },
                                                () => {
                                                    setActionRunning(false);
                                                    failureToast(
                                                        t('failureMessage')
                                                    );
                                                }
                                            )
                                        );
                                    }}
                                >
                                    {user.membership_is_active
                                        ? t('deactivateUser')
                                        : t('activateUser')}
                                </Button>
                                <Button
                                    isDisabled={actionRunning}
                                    height='32px'
                                    width='50%'
                                    onClick={async () => {
                                        setActionRunning(true);
                                        try {
                                            await api.sendInvitation(
                                                organizationId,
                                                user.membership_id
                                            );
                                            setActionRunning(false);
                                            successToast(t('invitationSent'));
                                        } catch (e: unknown) {
                                            setActionRunning(false);
                                            failureToast(
                                                t('failedToResendInvitation')
                                            );
                                            throw e;
                                        }
                                    }}
                                >
                                    {t('resendInvitationEmail')}
                                </Button>
                            </HStack>
                        </VStack>
                    </StaffOnlyCard>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default UserDrawer;
