import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    VStack,
    Text,
    FormControl,
    Input,
    FormLabel,
    Select,
    HStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NewUserParamsType } from '../types/reducers/organizations';
import { inputStyles, selectStyle, spacing4 } from '../util/styles';
import { isEmpty } from 'lodash';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (newUserFormValues: NewUserParamsType) => void;
    finalRef?: React.RefObject<HTMLElement>;
}

const AddUserModal = ({ isOpen, onClose, onSubmit, finalRef }: ModalProps) => {
    const { t } = useTranslation('addUserModal');
    const { t: to } = useTranslation('organizationPage');
    const { t: tc } = useTranslation('common');

    const initialFormValues: NewUserParamsType = {
        email: '',
        display_name: '',
        preferred_language: 'en',
        role: 'USER',
    };

    const [newUserFormValues, setNewUserFormValues] =
        useState<NewUserParamsType>(initialFormValues);

    const handleInputChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name: inputType, value } = event.target;
        setNewUserFormValues({
            ...newUserFormValues,
            [inputType]: value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(newUserFormValues);
    };

    const handleClose = () => {
        setNewUserFormValues(initialFormValues);
        onClose();
    };

    const renderRequiredText = (text: string) => (
        <HStack gap='3px'>
            <Text variant='small'>{text}</Text>
            <Text variant='small' color='red'>
                *
            </Text>
        </HStack>
    );

    const displayNameIsInvalid: boolean = isEmpty(
        newUserFormValues.display_name
    );
    const emailIsInvalid: boolean = isEmpty(newUserFormValues.email);
    const preferredLanguageIsInvalid: boolean = isEmpty(
        newUserFormValues.preferred_language as string
    );
    const roleIsInvalid: boolean = isEmpty(newUserFormValues.role);

    const isFormInvalid =
        displayNameIsInvalid ||
        emailIsInvalid ||
        preferredLanguageIsInvalid ||
        roleIsInvalid;

    return (
        <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('addUser')}</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={handleSubmit}>
                    <ModalBody
                        display='flex'
                        flexFlow='column'
                        gap='24px'
                        pt='0px'
                    >
                        <VStack spacing={2} pb={2} pt={2} gap={spacing4}>
                            <FormControl>
                                <FormLabel variant='small'>
                                    {renderRequiredText(
                                        t('displayNameFormLabel')
                                    )}
                                </FormLabel>
                                <Input
                                    {...inputStyles}
                                    name='display_name'
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel variant='small'>
                                    {renderRequiredText(t('emailFormLabel'))}
                                </FormLabel>
                                <Input
                                    {...inputStyles}
                                    type='email'
                                    name='email'
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <FormControl isInvalid={preferredLanguageIsInvalid}>
                                <FormLabel>
                                    {renderRequiredText(
                                        t('preferredLanguageFormLabel')
                                    )}
                                </FormLabel>
                                <Select
                                    {...selectStyle}
                                    name='preferred_language'
                                    onChange={handleInputChange}
                                >
                                    <option value='en'>{tc('en')}</option>
                                    <option value='es'>{tc('es')}</option>
                                </Select>
                            </FormControl>
                            <FormControl isInvalid={roleIsInvalid}>
                                <FormLabel>
                                    {renderRequiredText(t('roleFormLabel'))}
                                </FormLabel>
                                <Select
                                    {...selectStyle}
                                    name='role'
                                    onChange={handleInputChange}
                                >
                                    <option value='USER'>{to('USER')}</option>
                                    <option value='READ_ONLY'>
                                        {to('READ_ONLY')}
                                    </option>
                                    <option value='ADMIN'>{to('ADMIN')}</option>
                                </Select>
                            </FormControl>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='primary' mr={3} onClick={handleClose}>
                            {tc('discard')}
                        </Button>
                        <Button
                            variant='submit'
                            mr={3}
                            type='submit'
                            isDisabled={isFormInvalid}
                        >
                            {t('addUser')}
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default AddUserModal;
